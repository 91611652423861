import React, { useState } from "react";

const EditTodoForm = ({ todo, updateTodo, setEditTodo }) => {
  const [text, setText] = useState(todo.text);
  const [description, setDescription] = useState(todo.description);
  const [priority, setPriority] = useState(todo.priority);
  const [date, setDate] = useState(todo.date);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text || !description || !priority || !date) {
      alert("Tarefa inválida! Os campos precisam ser preenchidos.");
    } else {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    updateTodo(todo.id, text, description, priority, date);
  };

  const handleClose = () => {
    setEditTodo(null);
  };

  return (
    <div className="edit-todo-form">
      <h2>Editar tarefa:</h2>
      <form onSubmit={handleSubmit}>
        <input value={text} onChange={(e) => setText(e.target.value)} />
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <select value={priority} onChange={(e) => setPriority(e.target.value)} className="priority-edit">
          <option value="Baixa">Baixa</option>
          <option value="Média">Média</option>
          <option value="Alta">Alta</option>
        </select>
        <button className="complete" type="submit" >Atualizar</button>
        <button className="delete" onClick={handleClose}>Cancelar</button>
      </form>
    </div>
  );
};

export default EditTodoForm;
