import React from "react";

const Todo = ({ todo, removeTodo, completeTodo, editTodoItem }) => {
  const todoDate = new Date(todo.date);

  const formatDate = (date) => {
    const localDate = new Date(date);

    const offset = localDate.getTimezoneOffset() / 60;

    localDate.setHours(localDate.getHours() + offset);

    const day = localDate.getDate().toString().padStart(2, "0");
    const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
    const year = localDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div
      className="todo"
      style={{ textDecoration: todo.isCompleted ? "line-through" : "" }}
    >
      <div className="content">
        <h4>{todo.text}</h4>
        <p className="description">{todo.description}</p>
        <p className="date">
          <em>
            <strong>Data:</strong> {formatDate(todoDate)}
          </em>
        </p>
        <p className="priority">
          <em>
            <strong>Prioridade:</strong> {todo.priority}
          </em>
        </p>
      </div>
      <div>
        <button className="edit" onClick={() => editTodoItem(todo.id)}>
          Editar
        </button>
        <button className="complete" onClick={() => completeTodo(todo.id)}>
          Concluir
        </button>
        <button className="delete" onClick={() => removeTodo(todo.id)}>
          X
        </button>
      </div>
    </div>
  );
};

export default Todo;
