import React from "react";
import { Link } from "react-router-dom";

export default function Inicio() {
  const jwtToken = localStorage.getItem("jwtToken");
  const username = localStorage.getItem("username");

  return (
    <div className="vh-100 bg-primary page-container">
      <div className="flex-container">
        <div className="container-inicio">
          <img className="home-image" src="home-image.jpg" alt="tarefas"></img>
          <h2>{username ? `Bem-vindo, ${username}!` : "Bem-vindo!"}</h2>
          <p>
            <em>Taskfy</em> é seu companheiro definitivo de produtividade! Liberte seu
            potencial com nosso aplicativo de lista de tarefas intuitivo e
            repleto de recursos. Organize suas tarefas sem esforço, defina
            prioridades e mantenha o controle sobre seus objetivos. Aumente sua
            produtividade e retome o controle do seu dia. Comece a realizar mais
            com <em>Taskfy</em> - onde a simplicidade encontra a eficiência.
          </p>
          {jwtToken ? (
            <Link to="/tarefas">
              <button className="btn btn-primary">Começe agora</button>
            </Link>
          ) : (
            <Link to="/cadastro">
              <button className="btn btn-primary">Criar conta</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

/* ts */