import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_SERVER_URL 

export default function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");

    if (jwtToken) {
      navigate("/");
    }
  }, [navigate]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const { data } = await response.json();
        const { token, user } = data;
        const { name } = user;
  
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("username", name);
  
        // Navigate to "/" before reloading
        navigate("/");
  
        window.location.reload();

      } else {
        const data = await response.json();
        alert(data.error || "Erro durante o login");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("Um erro ocorreu. Tente novamente mais tarde");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="login template d-flex justify-content-center align-items-center vh-100 bg-primary">
      <div className="form_container p-5 rounded bg-white">
        <form onSubmit={handleFormSubmit}>
          <h3 className="text-center">Entrar</h3>
          <div className="mb-2">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              name="email"
              placeholder="Digite seu email"
              className="form-control"
              value={formData.email}
              onChange={handleInputChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              name="password"
              placeholder="Digite sua senha"
              className="form-control"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="d-grid">
            <button className="btn btn-primary mt-2">Login</button>
          </div>
          <p className="text-center mt-2">
            Ainda não possui uma conta? <Link to="/cadastro">Cadastrar-se</Link>
          </p>
        </form>
      </div>
    </div>
  );
}
