import React, { useState, useEffect } from "react";
import { Link, BrowserRouter as Router, useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_SERVER_URL

export default function Cadastro() {
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");

    if (jwtToken) {
      navigate("/");
    }
  }, [navigate]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/cadastro`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        alert("Cadastrado com sucesso!");
        
        setFormData({
          name: "",
          email: "",
          password: "",
        });
        
      } else {
        const data = await response.json();
        alert(data.error || "Erro ao realizar cadastro");
      }
    } catch (error) {
      alert("Um erro ocorreu, por favor tente novamente mais tarde");
    }
  };

  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  return (
    <div className="cadastro template d-flex justify-content-center align-items-center vh-100 bg-primary">
      <div className="form_container p-5 rounded bg-white">
        <form onSubmit={handleFormSubmit}>
          <h3 className="text-center">Cadastrar-se</h3>
          <div className="mb-2">
            <label htmlFor="name">Nome:</label>
            <input
              type="text"
              name="name"
              placeholder="Digite seu nome de usuário"
              className="form-control"
              value={formData.name}
              onChange={handleInputChange}
              required="true"
              pattern="[A-Za-z ]+" title="O nome de usuário deve conter apenas letras (sem números ou caracteres especiais)"
              autoComplete="off"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              name="email"
              placeholder="Digite seu email"
              className="form-control"
              value={formData.email}
              onChange={handleInputChange}
              required="true"
              autoComplete="off"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              name="password"
              placeholder="Digite sua senha"
              className="form-control"
              value={formData.password}
              onChange={handleInputChange}
              required="true"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="
              Deve conter pelo menos um número e uma letra maiúscula e minúscula e pelo menos 8 ou mais caracteres"
            />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary mt-2">
              Criar conta
            </button>
          </div>
          <p className="text-center mt-2">
            Já possui uma conta? <Link to="/login">Entrar</Link>
          </p>
        </form>
      </div>
    </div>
  );
}
