import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cadastro from "./pages/Cadastro";
import Login from "./pages/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Navbar from "./components/Navbar";
import Inicio from "./pages/Inicio";
import Sobre from "./pages/Sobre";
import Tarefas from "./pages/Tarefas";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Inicio />}></Route>
          <Route path="/sobre" element={<Sobre />}></Route>
          <Route path="/tarefas" element={<Tarefas />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/cadastro" element={<Cadastro />}></Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

/* something */