import React from "react";

export default function Sobre() {
  return (
    <div className="sobre-page">
        <div className="container-sobre">
          <img className="about-img" src="about-img.jpg" alt="tarefas"></img>
          <h2>Sobre o Taskfy</h2>
          <p>
            Bem-vindo ao Taskfy, a sua solução abrangente de gestão de tarefas
            projetada para simplificar a sua vida e aumentar a sua
            produtividade. Seja você um profissional ocupado, um estudante
            equilibrando várias tarefas ou qualquer pessoa em busca de
            organização, o nosso gestor de tarefas está aqui para ajudar.
          </p>
          <h4>1. Criação e Edição de Tarefas:</h4>
          <p>
            Crie facilmente novas tarefas e personalize-as de acordo com suas
            necessidades. Edite tarefas existentes com facilidade para se
            adaptar a mudanças em seus planos.
          </p>
          <h4>2. Gestão de Prioridades:</h4>
          <p>
            Mantenha o foco no que é mais importante atribuindo prioridades às
            suas tarefas. Nosso gestor de tarefas permite que você categorize as
            tarefas como baixa, média ou alta prioridade, ajudando você a
            priorizar suas atividades de forma eficiente.
          </p>
          <h4>3. Datas e Ordenação:</h4>
          <p>
            Nunca mais perca um prazo! Estabeleça datas de vencimento para suas
            tarefas e ordene-as por data para manter o controle sobre sua
            agenda. Nossas opções intuitivas de ordenação facilitam a
            organização das tarefas de acordo com os prazos.
          </p>
          <h4>4. Descrições Detalhadas:</h4>
          <p>
            Adicione descrições detalhadas às suas tarefas para fornecer
            contexto e clareza. Capture todas as informações necessárias dentro
            de cada tarefa, garantindo que nada seja esquecido.
          </p>
          <h4>5. Busca e Filtro:</h4>
          <p>
            Encontre facilmente a tarefa desejada usando nossa poderosa
            funcionalidade de busca. Filtre as tarefas por título, prioridade,
            status de conclusão e muito mais, tornando simples localizar e focar
            em tarefas específicas.
          </p>
          <h4>6. Acompanhamento de Conclusão:</h4>
          <p>
            Marque tarefas como concluídas assim que terminar. Acompanhe suas
            conquistas e celebre seu progresso enquanto trabalha através da sua
            lista de tarefas.
          </p>
          <h4>7. Opções de Ordenação:</h4>
          <p>
            Ordene suas tarefas por prioridade (baixa, média, alta) ou por data.
            Personalize a visualização de acordo com suas preferências e estilo
            de trabalho, facilitando a abordagem das tarefas na ordem que fizer
            mais sentido para você.
          </p>
          <h4>8. Filtragem de Tarefas:</h4>
          <p>
            Veja tarefas com base no seu status de conclusão - filtre as tarefas
            para ver apenas aquelas que estão concluídas ou aquelas que ainda
            precisam de atenção. Essa funcionalidade ajuda a manter um foco
            claro no que ainda precisa ser feito. O Taskfy é mais do que apenas
            um gestor de tarefas; é o seu companheiro pessoal de produtividade.
            Cadastre-se agora para experimentar uma abordagem organizada e sem
            complicações para a gestão de tarefas.
          </p>
          
        </div>
      </div>
  );
}
