import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Todo from "../components/Todo";
import TodoForm from "../components/TodoForm";
import Search from "../components/Search";
import Filter from "../components/Filter";
import EditTodoForm from "../components/EditTodoForm";


export default function Tarefas() {
  const navigate = useNavigate();
  const [todos, setTodos] = useState([
    {
      id: 1,
      text: "Título - Exemplo",
      description: "Descrição - Exemplo",
      priority: "Média",
      date: "2023-10-31",
      isCompleted: false,
    },
  ]);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("Priority");
  const [editTodo, setEditTodo] = useState(null);

  const addTodo = (text, description, priority, date) => {
    const newTodos = [
      ...todos,
      {
        id: Math.floor(Math.random() * 10000),
        text,
        description,
        priority,
        date,
        isCompleted: false,
      },
    ];
    setTodos(newTodos);
  };

  const removeTodo = (id) => {
    const newTodos = todos.filter((todo) => todo.id !== id);
    setTodos(newTodos);
  };

  const completeTodo = (id) => {
    const newTodos = todos.map((todo) =>
      todo.id === id ? { ...todo, isCompleted: !todo.isCompleted } : todo
    );
    setTodos(newTodos);
  };

  const editTodoItem = (id) => {
    const todoToEdit = todos.find((todo) => todo.id === id);
    setEditTodo(todoToEdit);
  };

  const updateTodo = (id, text, description, priority, date) => {
    const updatedTodos = todos.map((todo) =>
      todo.id === id
        ? {
            ...todo,
            text,
            description,
            priority,
            date,
          }
        : todo
    );
    setTodos(updatedTodos);
    setEditTodo(null);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");

    if (!jwtToken) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="tarefas-page">
      <div className="app">
        <h1>Lista de tarefas</h1>
        <Search search={search} setSearch={setSearch} />
        <Filter filter={filter} setFilter={setFilter} setSort={setSort} />
        <div className="todo-list">
          {todos
            .filter((todo) =>
              filter === "All"
                ? true
                : filter === "Completed"
                ? todo.isCompleted
                : !todo.isCompleted
            )
            .filter((todo) =>
              todo.text.toLowerCase().includes(search.toLowerCase())
            )
            .sort((a, b) => {
              if (sort === "Priority") {
                if (a.priority === "Alta" && b.priority !== "Alta") {
                  return -1;
                } else if (a.priority !== "Alta" && b.priority === "Alta") {
                  return 1;
                }

                if (a.priority === "Média" && b.priority !== "Média") {
                  return -1;
                } else if (a.priority !== "Média" && b.priority === "Média") {
                  return 1;
                }

                return 0;
              } else if (sort === "Date") {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA - dateB;
              } else {
                return 0;
              }
            })
            .map((todo) => (
              <Todo
                key={todo.id}
                todo={todo}
                removeTodo={removeTodo}
                completeTodo={completeTodo}
                editTodoItem={editTodoItem}
              />
            ))}
        </div>
        <TodoForm addTodo={addTodo} />
        {editTodo && (
          <EditTodoForm
            todo={editTodo}
            updateTodo={updateTodo}
            setEditTodo={setEditTodo}
          />
        )}
      </div>
    </div>
  );
}
