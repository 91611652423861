import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  const jwtToken = localStorage.getItem("jwtToken");
  const username = localStorage.getItem("username");

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("username");
    window.location.reload();
  };

  return (
    <div className="container">
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light ${isNavbarExpanded ? "navbar-expanded" : ""}`}
      >
        <Link className="navbar-brand" to="#">
          Taskfy
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isNavbarExpanded}
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavbarExpanded ? "show" : ""}`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                <i className="fa-solid fa-house-user"></i> Início
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/sobre">
                <i className="fa-solid fa-circle-info"></i> Sobre
              </Link>
            </li>
            {jwtToken && (
              <li className="nav-item">
                <Link className="nav-link" to="/tarefas">
                  <i className="fa-solid fa-list-check"></i> Tarefas
                </Link>
              </li>
            )}
          </ul>
        </div>
        {jwtToken ? (
          <div>
            <span className="navbar-text me-2">{`Olá, ${username}`}</span>
            <button className="btn btn-danger" onClick={handleLogout}>
              Sair
            </button>
          </div>
        ) : (
          !isNavbarExpanded && (
            <form className="d-flex gap-2">
              <Link to="/cadastro">
                <button className="btn btn-primary">Cadastro</button>
              </Link>
              <Link to="/login">
                <button className="btn btn-success">Login</button>
              </Link>
            </form>
          )
        )}
      </nav>
    </div>
  );
}
