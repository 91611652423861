import { useState, React } from "react";

const TodoForm = ({ addTodo }) => {
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [date, setDate] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value || !description || !priority || !date) {
      alert("Tarefa inválida! Os campos precisam ser preenchidos.");
      return;
    }
    addTodo(value, description, priority, date);
    setValue("");
    setDescription("");
    setPriority("");
    setDate("");
  };

  return (
    <div className="todo-form">
      <h2>Criar tarefa:</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Digite o título"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <input
          type="text"
          placeholder="Digite a descrição"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
         <input
          type="date"
          value={date}
          placeholder="DD/MM/YYYY"
          onChange={(e) => setDate(e.target.value)}
        />
        <select value={priority} onChange={(e) => setPriority(e.target.value)}>
          <option value="">Selecione a prioridade</option>
          <option value="Baixa">Baixa</option>
          <option value="Média">Média</option>
          <option value="Alta">Alta</option>
        </select>
       
        <button type="submit" className="criar-tarefa-btn">Criar tarefa</button>
      </form>
    </div>
  );
};

export default TodoForm;
